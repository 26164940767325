import React from 'react'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import { MenuItem } from '@material-ui/core'
import { NavLink } from 'react-router-dom'


class SwipeableMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  handleToggle = () => {
    this.setState({ open: !this.state.open });
  }

  render = () => {
    return (
      <React.Fragment>
        <IconButton 
          aria-label="open drawer"
          edge="start"
          onClick={ this.handleToggle }
        >
          <MenuIcon />
        </IconButton>
        <SwipeableDrawer 
          anchor="right"
          open={ this.state.open }
          onOpen={ this.handleToggle }
          onClick={ this.handleToggle }
          onClose={ this.handleToggle }
        >
          { this.props.children }
        </SwipeableDrawer>
      </React.Fragment>
    );
  }
}

export const ListItemLink = React.forwardRef((props, ref) => {
  const { to, children } = props;

  return (
      <NavLink to={to} {...props} ref={ref} style={{ textDecoration: "none"}}>
        <ListItem button>
          { children }
        </ListItem>
      </NavLink>
  );
});

export const MenuItemLink = React.forwardRef((props, ref) => {
  const { 
    selected, 
    onClick= () => {}, 
    children 
  } = props;

  return (
      <NavLink {...props} ref={ref} style={{ textDecoration: "none"}}>
        <MenuItem button selected={selected} onClick={ onClick }>
          { children }
        </MenuItem>
      </NavLink>
  );
});

export default class MobileMenu extends React.Component {
  render = () => {
    let { children, ...props } = this.props;

    return (
      <SwipeableMenu { ...props }>
        { children }
      </SwipeableMenu>
    );
  }
}
