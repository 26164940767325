import React from "react";
import { NavLink, Route } from "react-router-dom";

import { IconButton, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles";

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';


const styles = makeStyles(theme => ({
    footer: {
        minHeight: 64,
        // maxHeight: "35vh",
        width: "100%",
        display: "flex",
        flexShrink: 0,
        flexFlow: "wrap",
        justifyContent: "center",
        // alignItems: "center",
        background: "black",
        borderTop: "4px solid rgba(222, 222, 222, 0.23)",
        padding: theme.spacing(2),
        color: "rgba(255, 255, 255, 0.6)",
        
    },
    link: {
        color: "rgba(180, 180, 180, 0.6)",
        margin: `${theme.spacing(1)}px 0 0`,
        textDecoration: "none",
        "&:hover": {
            color: "rgba(200, 200, 200, 0.65)"
        }
    },
    linkIcon: {
        maxHeight: "42px",
        maxWidth: "42px",
        margin: `0 ${theme.spacing(1)}px 0 0`,
        backgroundColor: "rgba(80, 80, 80, 0.15)",
        boxShadow: "0 0 0 3px rgba(150, 150, 150, 0.1) inset"
    },
    linkSeparator: {
        margin: `${theme.spacing(1)}px ${theme.spacing(0.5)}px 0`,
        color: "rgba(180, 180, 180, 0.6)",
    },
    innerIcon: {
        maxHeight: "24px",
        maxWidth: "24px"
    },
    column: {
        display: "flex",
        flexDirection: "column",
        margin: `0 ${theme.spacing(3)}px ${theme.spacing(2)}px`
    },
    title: {
        color: "rgba(225, 225, 225, 0.85)"
    },
    lineBreak: {
        width: "100%"
    },
    subhr: {
        width: "80%", 
        height: 1, 
        border: 0, 
        margin: "auto",
        backgroundColor: "rgba(222, 222, 222, 0.23)",
    },
    footerBase: {
        marginTop: `${theme.spacing(2)}px`
    }
}));

function makeIconButton(icon, text, classes) {
    console.log(classes.linkIcon);
    return (
        <>
            <IconButton className={ classes.linkIcon }>
                { React.cloneElement(icon, {className: classes.innerIcon}) }
            </IconButton>
            { text }
        </>
    )
}

function buildLinks(linkMap, classes) {
    return Object.entries(linkMap).map(([url, renderable]) => {
        if (url.match(/^((https?:\/\/)?[\w.]*m8rxrecords\.com|^\/)/g)) {
            return (
                <Route path={ url } className={ classes.link } key={ url }>
                    { renderable }
                </Route>
            );
        } else {
            return (
            <a 
                href={ url } 
                className={ classes.link }
                key={ url }
            >
                { renderable }
            </a>
            );
        }
    });
    
}

function musicLinks(classes) {
    let links = {
        "http://open.spotify.com/user/39oo7k1ufocsdj3wxj9vak0ri?si=H_46Ih-PQJeKEK8PgLq8HA": makeIconButton(
            <img src="/img/icons/spotify/icons/01_RGB/02_PNG/Spotify_Icon_RGB_White.png" alt="Spotify"/>,
            "Spotify",
            classes
        ),
        "http://www.youtube.com/channel/UC37YAHu0FOCfJ9mK4tUt1Kw": makeIconButton(
            <img src="/img/icons/youtube/digital_and_tv/yt_icon_mono_dark.png" alt="YouTube"/>,
            "YouTube",
            classes
        ),
        "http://www.soundcloud.com/m8rxrecords": makeIconButton(
            <img src="/img/icons/soundcloud/soundcloud_logo_white_24x24.png" alt="SoundCloud"/>,
            "SoundCloud",
            classes
        ),
        
    }

    return buildLinks(links, classes);
}


function socialsLinks(classes) {
    let links = {
        "http://www.facebook.com/m8rxrecords": makeIconButton(<FacebookIcon/>, "Facebook", classes),
        "http://www.instagram.com/m8rxrecords": makeIconButton(<InstagramIcon/>, "Instagram", classes),
        "http://www.twitter.com/m8rxrecords": makeIconButton(<TwitterIcon/>, "Twitter", classes)
    };
    
    return buildLinks(links, classes);
}

function FooterTitle(props) {
    const { children, classes } = props;
    
    return (
        <Typography variant="subtitle1" className={ classes.title }>
            { children }
        </Typography>
    );
}

function FooterColumn(props) {
    const { classes, children } = props;

    return (
        <div className={ classes.column }>
            { children }
        </div>
    )
}

export function Footer(props) {
    const { children } = props;
    const classes = styles();

    return (
        <div className={ classes.footer }>
            { children }
            <FooterColumn classes={ classes }>
                <FooterTitle classes={ classes }>
                    CONNECT WITH US
                </FooterTitle>
                { socialsLinks(classes) }
            </FooterColumn>
            <FooterColumn classes={ classes }>
                <FooterTitle classes={ classes }>
                    RECORDED MUSIC
                </FooterTitle>
                { musicLinks(classes) }
            </FooterColumn>
            <div className={ classes.lineBreak }>
                <hr className={ classes.subhr }/>
            </div>
            <div className={ classes.footerBase }>
                <NavLink to="/privacy" className={ classes.link }>
                    Privacy Policy
                </NavLink>
                <span className={ classes.linkSeparator }>•</span>
                <NavLink to="/termsandconditions" className={ classes.link }>
                        Terms &amp; Conditions
                </NavLink>
                <span className={ classes.linkSeparator }>•</span>
                <NavLink to="/contact" className={ classes.link }>
                    Contact Us
                </NavLink>
                <span className={ classes.linkSeparator }>•</span>
                &copy; { new Date().getFullYear() } M8RX Records, LLC.
            </div>
        </div>
    )
}
