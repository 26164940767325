import React, { useState } from 'react';

import { 
  NavLink
} from 'react-router-dom';

// import * as path from "path";

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';

import MobileMenu, { ListItemLink, MenuItemLink } from '../mobile/menu'


const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  appBar: {
    background: "black", 
    borderBottom: "4px solid rgba(222, 222, 222, 0.23)",
    position: "fixed",
    verticalAlign: "middle"
  },
  appBarTitle: {
    flexGrow: 1,
  },
  homeLink: {
    textDecoration: "none",
    maxHeight: 64, 
    "&:visited": {
      color: "inherit"
    }
  },
  homeLinkContainer: {
    flexGrow: 1
  },
  appBarLink: {
    color: "white",
  },
  tabs: {
    flexGrow: 1,
    alignItems: "end"
  },
  tabIndicator: {
    style: {
      height: "100%"
    }
  }
}));

export function AppBarMobileLinks(props) {
  const classes = useStyles();
  const { links = [] } = props;

  return (
    <MobileMenu>
      { links.map(link => {
        return (
          <ListItemLink 
            key={ link }
            className={ classes.appBarLink }
            to={ `${link.toLowerCase()}` } 
          >
            { `${link[0].toUpperCase()}${link.slice(1)}` }
          </ListItemLink>
        );
      }) }
    </MobileMenu>
  );
}

export default function ButtonAppBar(props) {
  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { 
    links = ["About", "Submissions", "Contact"]
  } = props;

  let [ selected, setSelected ] = useState();

  function AppBarTabs(props) {
  
    return (
      <>
        { links.map((link, i) => {
          return (
            <MenuItemLink 
              key={ link }
              className={ classes.appBarLink }
              to={ `${link.toLowerCase()}` } 
              onClick={ () => setSelected(i) }
              selected={ selected === i }
            >
              { `${link.toUpperCase()}` }
            </MenuItemLink>
          );
        }) }
        </>
    );
  }

  function menu(isMobile, links) {
    return isMobile ? <AppBarMobileLinks links={ links}/> : <AppBarTabs links={ links }/>;
  }

  return (
    <>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <div className={ classes.homeLinkContainer }>
            <NavLink to="/" style={{ textDecoration: "none" }} className={ classes.homeLink } onClick={ () => setSelected(undefined) }>
              <img src="/img/logos/PNG/Symbol/Symbol_White.png" alt="M8RX Logo" style={{ maxHeight: "64px" }}/>
            </NavLink>
          </div>
          { menu(isMobile, links) }
        </Toolbar>
      </AppBar>
      <Toolbar className={ classes.noDisplay }/>
    </>
  );
}