import React from "react";

import { Link, Typography } from "@material-ui/core";

import LinkBehavior from "../nav/link";


export default class About extends React.Component {
    render() {
        return (
            <>
                <Typography variant="h5">
                    <b>Our Vision</b>
                </Typography>
                <p>
                    Founded by four ravers with a mission to better unite our world in an environment where we can truly grow with our own integrity.
                    Much of this world has been overrun with suggestive influence of how much humanity we should give, how much a life is worth, and 
                    how we should vote.
                    It is within our own collective selves, that we can shape our world around us in a way that is equitable, sustainable, and integral 
                    to who we are at our core.
                </p>
                <p>
                    M8RX is not just a means to release and promote music. We are a story that is spoken by many tongues. Our identity is the intersection 
                    of our inner muses.
                    We seek to immerse each other in a universe full of exploration, idealization, and real-world application. 
                </p>
                <p>
                    Walk with us virtually. Exchange creativity while we explore this journey together. Let's show the world how our vision for a better 
                    future is achievable and supportable. We are all in this world, and any world that we create here at M8RX, together.
                </p>
                <br/>
                <br/>
                <Typography variant="h5">
                    <b>Fine Tuning</b>
                </Typography>
                <p>
                    The genres we primarily focus on are glitch, glitchstep, melodic dubstep, brostep (melodic derivatives), and chill future bass/trap.
                    We do have an interest to take our story in the musical and visual direction that we all collectively narrate. 
                    This means that we may continue to broaden our genres as our story unfolds.
                </p>
                <br/>
                <br/>
                <Typography variant="h5">
                    <b>Behind the Screens</b>
                </Typography>
                <p>
                    M8RX is a registered LLC headquartered out of Frisco, Texas (USA), but founded by natives of Chicago, Denver, Dallas, and New Jersey. 
                    Our founders have experience in label management, artist social media management, and business development.
                    We are entirely self-funded and have not taken any investor funds. A small group of independent graphic artists bring imagery to our story.
                </p>
                <p>
                    Before M8RX, we founded multiple EDM communities as large as 16,000 active contributing members. 
                    One of those communities was recognized as 
                    the <Link href="https://iedm.com/blogs/onblast-edm-blog/best-of-2017">Best Fanbase of 2017</Link> by iEDM.
                    <br/>
                    We also have experience in running an EDM label of over 30,000 followers with releases by artists from all over the world. 
                </p>
                <br/>
                <br/>
                <Typography variant="h5">
                    <b>Help Tell Our Story</b>
                </Typography>
                <p>
                    For contributing to our creative story narratively or visually, please <Link component={LinkBehavior} to="/contact">contact us</Link>. 
                </p>
                <br/>
            </>
        )
    }
}