import React, { useState } from 'react'

import { 
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  makeStyles,
  useMediaQuery
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

import Form from '../../common/form'
import Request from '../../lib/http'


const useStyles = makeStyles(theme => ({
  textField: {
    background: "black",
    opacity: 0.90,
    color: theme.palette.text.secondary,
  },
  dialogTitle: {
    paddingLeft: 0,
    paddingRight: 0
  }
}));

export default function ContactForm(props) {
  const { 
    variant,
    LayoutProps = { 
      justifyContent: "center",
      spacing: 4,
      xs: 10,
      
    } 
  } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const inputProps = { className: classes.textField };

  let [ name, setName ] = useState("");
  let [ emailAddress, setEmailAddress ] = useState("");
  let [ message, setMessage ] = useState("");

  let [ dialog, setDialog ] = useState({ open: false, title: "", message: "" });

  function handleDialogClose() {
    setDialog({ title: "", message: "", open: false });
  }

  function handleSubmit(e) {
    e.preventDefault();
    setDialog({ ...dialog, open: true });

    let req = new Request("POST", "/api/email/send", { 
      to: emailAddress,
      cc: "support@m8rxrecords.com",
      template: "contactus",
      dynamic_template_data: {
        name: name, 
        message: message
      }
    });

    req.fetch()
      .then(response => {
        if (response.ok) {
          setDialog({ 
            ...dialog, 
            open: true, 
            title: "Message sent.", 
            message: "You will receive a confirmation email shortly." 
          });
        } else {
          setDialog({ 
            ...dialog, 
            open: true, 
            title: "Message failed to deliver.", 
            message: `${response.statusText}` 
        });
        }
      })
      .catch(error => {
        setDialog({ 
          ...dialog, 
          open: true, 
          title: "Message failed to deliver.", 
          message: `${error.message}` 
      });
    });
  } 

  return (
    <>
      <Form 
        LayoutProps={ LayoutProps }
        onSubmit={ handleSubmit }
      >
        <TextField
          variant={variant} 
          label="Name"
          value={ name }
          onInput={ e => setName(e.target.value) }
          InputProps={ inputProps }
          required
          fullWidth
          margin="dense"
        />
        <TextField 
          variant={variant} 
          label="Email" 
          type="email"
          value={ emailAddress }
          onInput={ e => setEmailAddress(e.target.value) }
          InputProps={ inputProps }
          required
          fullWidth
          margin="dense"
        />
        <TextField 
          variant={variant}
          label="Message" 
          rows="10"
          value={ message }
          onInput={ e => setMessage(e.target.value) }
          InputProps={ inputProps }
          multiline 
          required
          fullWidth
          margin="dense"
        />
      </Form>
      <Dialog 
          fullScreen={ fullScreen } 
          open={ dialog.open } 
          onClose={ handleDialogClose }
          disableScrollLock={ true }
      >
          <DialogContent>
              { dialog.message ?
                  <> 
                      <DialogTitle className={ classes.dialogTitle }>
                          { dialog.title }
                      </DialogTitle>
                      <DialogContentText>
                          { dialog.message }
                      </DialogContentText>
                  </>
              :
                  <CircularProgress/>
              }
          </DialogContent>
      </Dialog>
    </>
  );
}

