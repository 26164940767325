import React from 'react';
 
import { 
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';

import { 
  makeStyles, 
  responsiveFontSizes, 
  Container
} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import ButtonAppBar from './nav/bar';
import Title from './common/title';
import Tiles from './common/tiles';
import { Footer } from './nav/footer';

import About from "./views/about";
import ContactForm from './views/forms/contact';
import SubmissionsForm from './views/forms/submissions';
import PrivacyPolicy from './views/legal/privacy';
import TermsAndConditions from './views/legal/termsconditions'



var darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    background: {
      default: "transparent" 
    },
    primary: {
      main: "#ad9239"
    }
  }
});

darkTheme = responsiveFontSizes(darkTheme);

const useStyles = makeStyles(theme => ({
  app: {
    flexGrow: 1,
    minHeight: "75%"
  },
  body: {
    backgroundImage: `linear-gradient(
      to right bottom,
      rgb(0, 167, 255, 0.25) 30%,
      rgb(0, 0, 0),
      rgb(255, 0, 254, 0.25) 70%
    );`,
    minHeight: "100vh",
    minWidth: "100vw",
    position: "fixed",
    zIndex: -1,
    display: "flex",
    flexDirection: "column",
  },
  content: {
    padding: `${theme.spacing(4)}px 0px`,
    height: "100%"
  },
  
}));

export default function App(props) {
  const classes = useStyles();
  let spaces = [];

  return (
    <>
      <div>
        <div className={classes.body} />
        <Tiles 
          tileSize={36} 
          colors={["#2b2b3b", "#15151e"]}
          spaces={spaces}
        />
        <Tiles 
          tileSize={18} 
          colors={["#2b2b3b", "#15151e"]}
          spaces={spaces}
        />
      </div>
      
      <ThemeProvider theme={darkTheme}>
        <CssBaseline/>
        <Router>
          <Container className={ classes.app }>
          <ButtonAppBar links={["About", "Submissions", "Contact"]}/>
          <div className={ classes.content }>
            <Switch>
              <Route path="/about">
                <Title align="center">
                  About Us
                </Title>
                <About/>
              </Route>
              <Route path="/submissions">
                <Title align="center">
                  Show Us Your Music
                </Title>
                <SubmissionsForm variant="outlined"/>
              </Route>
              <Route path="/visuals">
                <Title align="center">
                  Behind The Imagery
                </Title>
              </Route>
              <Route path="/contact">
                <Title align="center">
                  Contact Us
                </Title>
                <ContactForm variant="outlined"/>
              </Route>
              <Route path="/privacy">
                  <PrivacyPolicy/>
              </Route>
              <Route path="/termsandconditions">
                  <TermsAndConditions/>
              </Route>
            </Switch>
            </div>
          </Container>
          <Footer/>
        </Router>  
      </ThemeProvider>
    </>
  );
}
