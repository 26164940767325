export default class Request {
    // Accept header required to not be text/html in order to forward to proxy
    constructor(method = "GET", url, params = {}, headers = { accept: '*/*' }) {   
        this.method = method;
        this.url = url;
        this.params = params;
        this.headers = headers;
    }

    get json() {
        return {
            method: this.method,
            headers: {
                "Content-Type": "application/json",
                ...this.headers
            },
            body: JSON.stringify(this.params)
        }
    }

    async fetch(url = this.url) {
        let json = this.json;
        return await fetch(url, json);
    }
}