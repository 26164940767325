import React, { useState } from 'react'

import { 
    CircularProgress,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField,
    makeStyles,
    useMediaQuery
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import { useTheme } from '@material-ui/core/styles'

import Form from "../../common/form"
import Request from '../../lib/http'
import FileUploader, { FileCard } from "../../upload/file"


const useStyles = makeStyles(theme => ({
    textField: {
        background: "black",
        opacity: 0.90,
        color: theme.palette.text.secondary,
        "&.Mui-focused": {
            borderColor: "red"
        }
    },
    dialogTitle: {
        paddingLeft: 0,
        paddingRight: 0
    }
}));


export default function SubmissionsForm(props) {
    let [ name, setName ] = useState("");
    let [ artist, setArtist ] = useState("");
    let [ emailAddress, setEmailAddress ] = useState("");
    let [ spotifyLink, setSpotifyLink ] = useState("");
    let [ soundcloudLink, setSoundcloudLink ] = useState("");
    let [ youtubeLink, setYoutubeLink ] = useState("");
    let [ beatportLink, setBeatportLink ] = useState("");

    let [ files, setFiles ] = useState([]);
    let [ dialog, setDialog ] = useState({ open: false, title: "", message: "" });

    const classes = useStyles();
    const InputProps = { className: classes.textField };
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    let {
        variant,
        LayoutProps = { 
            justifyContent: "center",
            xs: 10,
        } 
    } = props;

    function handleDialogClose() {
        setDialog({ title: "", message: "", open: false });
    }

    function fileToAttachment(file) {
        return new Promise(resolve => {
            let reader = new FileReader();
            reader.onload = () => {
                resolve({
                    content: btoa(reader.result),
                    filename: file.name,
                    type: file.type,
                    disposition: "attachment"
                });
            };
            reader.readAsBinaryString(file);
        });
    }

    function sendEmail(attachments) {
        if (emailAddress !== "" && attachments.length > 0) {
            let req = new Request("POST", "/api/email/send", {
                to: emailAddress, 
                cc: "promo@m8rxrecords.com",
                attachments: attachments,
                template: "submissions",
                dynamic_template_data: {
                    name: name,
                    artist: artist,
                    soundLinks: [
                        {
                            platform: "Spotify",
                            url: spotifyLink
                        },
                        {
                            platform: "SoundCloud",
                            url: soundcloudLink
                        },{
                            platform: "YouTube",
                            url: youtubeLink
                        },{
                            platform: "Beatport",
                            url: beatportLink
                        }
                    ]
                }
            });
            req.fetch()
                .then(result => {
                    if (result.ok) {
                        setDialog({ 
                            ...dialog, 
                            open: true, 
                            title: "Message sent.", 
                            message: "You will receive a confirmation email shortly." 
                        });
                    } else {
                        setDialog({ 
                            ...dialog, 
                            open: true, 
                            title: "Message failed to deliver.", 
                            message: `${result.statusText}` 
                        });
                    }
                    
                    return result;
                })
                .catch(error => {
                    setDialog({ 
                        ...dialog, 
                        open: true, 
                        title: "Message failed to deliver.", 
                        message: `Error is: ${error.message}` 
                    });
                    throw error;
                });
        }
    }

    function handleSubmit(e) {
        e.preventDefault();
        setDialog({ ...dialog, open: true });
        Promise.all(files.map(file => fileToAttachment(file)))
            .then(attachments => sendEmail(attachments));
    }

    function validFileType(file) {
        let { 
            types = [/^audio/g]
        } = props;
        
        return types.some(type => {
            if (typeof type === "string") {
                type = type.replace(/\/\*$/g, "");
            }
            return file.type.match(type);
        }); 
    }

    function handleChange(e) {
        let _files = [];

        for (const file of e.target.files) {
            if (validFileType(file)){
                _files.push(file);
            }
        }

        setFiles(_files);
    }

    function handleDragOver(e) {
        e.preventDefault();
    }
    
    function handleDrop(e) {
        e.preventDefault();
        let _files = [];

        for (const item of e.dataTransfer.items) {
            if (item.kind === "file") {
                let file = item.getAsFile();

                if (validFileType(file)) {
                    _files.push(file);
                }
            }
        }

        setFiles([ ...files, ..._files ])
    }

    let fields = [
        [ name,           setName,           "Name",            true  ],
        [ artist,         setArtist,         "Artist Name",     true  ],
        [ emailAddress,   setEmailAddress,   "Email Address",   true  ],
        [ spotifyLink,    setSpotifyLink,    "Spotify Link",    false ],
        [ soundcloudLink, setSoundcloudLink, "SoundCloud Link", false ],
        [ youtubeLink,    setYoutubeLink,    "YouTube Link",    false ],
        [ beatportLink,   setBeatportLink,   "Beatport Link",   false ]
    ];

    return (
        <>
            <Form
                encType="multipart/form-data" 
                LayoutProps={ LayoutProps }
                buttonLabel="Send"
                onSubmit={ handleSubmit }
            >
                { fields.map(([ getter, setter, fieldName, required ]) => {
                    let spaced = fieldName.replace("Link", " Link");
                    return (
                        <TextField
                            key={ fieldName }
                            variant={ variant } 
                            label={ `${spaced[0].toUpperCase() + spaced.slice(1)}` }
                            value={ getter }
                            onInput={ e => setter(e.target.value) }
                            InputProps={ InputProps }
                            required={ required }
                            margin="dense"
                            fullWidth
                        />  
                    );
                }) }
                <FileUploader onChange={ handleChange } onDragOver={ handleDragOver } onDrop={ handleDrop }>
                    { files.length > 0 ? files.map(file => {
                        return (
                            <FileCard 
                                file={ file } 
                                key={ file.name + file.lastModified } 
                                classes={ classes }
                            />
                        );
                    }) : 'Drag and drop your music here or use the "select files" button above'}
                </FileUploader>
            </Form>
            <Dialog 
                fullScreen={ fullScreen } 
                open={ dialog.open } 
                onClose={ handleDialogClose }
                disableScrollLock={ true }
            >
                <DialogContent>
                    { dialog.message ?
                        <> 
                            <DialogTitle className={ classes.dialogTitle }>
                                { dialog.title }
                                <IconButton aria-label="close" className={classes.closeButton} onClick={handleDialogClose}>
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContentText>
                                { dialog.message }
                            </DialogContentText>
                        </>
                    :
                        <CircularProgress/>
                    }
                </DialogContent>
            </Dialog>
        </>
    );
}
