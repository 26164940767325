
import React from 'react'
import { Grid, makeStyles, Box } from '@material-ui/core'

const useStyles = makeStyles({
  layout: {
    flexGrow: 1,
    margin: 0
  }
});

export default function Layout(props) {
  const classes = useStyles();
  let children, justifyContent;

  ({ children, justifyContent, ...props } = props);

  return (
    <Box display="flex" justifyContent={ justifyContent }>
      <Grid container item { ...props } className={ classes.layout }>
        { children }
      </Grid>
    </Box>
  );
}
