import React from 'react'
import { Button, Grid, makeStyles } from '@material-ui/core'
import Layout from './grid'


const useStyles = makeStyles(theme => ({
  gridButton: {
    justifyContent: "flex-end",
    marginLeft: "auto",
    marginTop: 16
  }
}));

export default function Form(props) {
  const classes = useStyles();
  let {
    children, 
    LayoutProps,
    autoComplete = "off",
    buttonLabel = "Submit",
    ...rest
  } = props;

  console.log(rest)
  
  return (
    <form 
      noValidate 
      autoComplete={ autoComplete } 
      { ...rest }
    >
      <Layout { ...LayoutProps }>
        { children }
          <Button 
            variant="contained" 
            type="submit" 
            disableElevation
            className={ classes.gridButton }
            
          >
            { buttonLabel }
          </Button>
      </Layout>
    </form>
  )
}

