import React from 'react'


function randomNumber(min, max) {
  if (max === undefined) {
    max = min;
    min = 0;
  }
  return Math.floor(Math.random() * max) + min;
}

class Rectangle {
  constructor(minX, minY, maxX, maxY) {
    if (minX >= maxX || minY >= maxY) {
      throw new RangeError(
        "Can not have min greater than max in Rectangle."
      );
    }
    this.minX = minX;
    this.minY = minY;
    this.maxX = maxX;
    this.maxY = maxY;
  }

  cut = (width, height) => {
    let rect = (x0, y0, x1, y1) => {
      try {
        return new Rectangle(x0, y0, x1, y1);
      } catch (e) {
        if (e instanceof RangeError) {
          return null;
        } else {
          throw e;
        }
      }
    }

    let rects = [];

    rects.push(
      rect(
        this.minX, 
        this.minY,
        this.minX + width,
        this.minY + height
      ),
      rect(
        this.minX + width,
        this.minY,
        this.maxX,
        this.minY + height
      ),
      rect(
        this.minX,
        this.minY + height,
        this.maxX,
        this.maxY
      )
    );

    return rects.filter(rect => rect);
  }
}

let _spaces = [];

class CoordinateGrid {
  constructor(
      width, 
      height, 
      step = 12, 
      spaces = _spaces
  ) {
    this.width = width;
    this.height = height;
    this.step = step;
    this.spaces = spaces;

    if (!spaces.length) {
      this.reset();
    }
  }

  reset = () => {
    this.clear();
    this.spaces.push(
      new Rectangle(0, 0, this.width, this.height)
    );
  }

  push = (...items) => {
    this.spaces.push(...items);
  }

  pop = () => {
    return this.spaces.pop();
  }

  shift = () => {
    return this.spaces.shift();
  }

  clear = () => {
    this.spaces.length = 0;
  }

  *[Symbol.iterator] () {
    for (let rect of this.spaces) {
      yield rect;
    }
  }
}

class Tile {
  constructor(canvas, x, y, color = "black", size = 14, alpha = 1.0) {
    this.canvas = canvas;
    this.x = x;
    this.y = y;
    this.size = size;
    this.color = color;
    this.alpha = alpha;
  }

  draw = (text, color = this.color, alpha = 1.0, font) => {
    let ctx = this.canvas.getContext("2d");

    if (!font) {
      let fontArgs = ctx.font.split(" ");
      font = `bold ${this.size}px ` + fontArgs.slice(1);
    } 
    // fontArgs = ctx.font.split(" ");
    // ctx.font = `bold ${this.size}px ` + fontArgs.slice(1);

    ctx.font = font;
    ctx.globalAlpha=alpha;
    ctx.fillStyle = color;
    ctx.shadowColor = color;
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;
    ctx.shadowBlur = 14;

    ctx.fillText(text, this.x, this.y);
  }

  clear = (delay) => {
    let ctx = this.canvas.getContext("2d");
    setTimeout(() => {
      ctx.clearRect(this.x, this.y, this.size, this.size);
    }, delay);
  }

  random = () => {
    let text;
    let chars = "M8RX";
    let n = randomNumber(1000);

    if (n >= 300) {
      let charCode = 0x030A0 + randomNumber(95);
      text = String.fromCharCode(charCode);
    } else if (n >= 200) {
      let ix = randomNumber(chars.length);
      text = chars[ix];
    }  else {
      text = randomNumber(10);
    }

    if (chars.indexOf(text) >= 0) {
      let colors = ["#ff00fe", "#00a7ff", "#00e4ff"];
      let color = colors[randomNumber(colors.length)];
      this.draw(text, color, Math.min(this.alpha - 0.05, 1.0), `bold ${this.size}px Courier New`);
    } else {
      this.draw(text, this.color, this.alpha);
    }
  }

  render = (delay) => {
    setTimeout(this.random, delay);
  }
}

export default class Tiles extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();

    this.colors = props.colors || ["red", "green", "blue"];
    this.spaces = props.spaces || [];
    this.grid = null
  }

  get canvas() {
    return this.ref.current;
  }

  get width() {
    return this.canvas.width;
  }

  set width(value) {
    this.canvas.width = value;
  }

  get height() {
    return this.canvas.height;
  }

  set height(value) {
    this.canvas.height = value;
  }

  componentDidMount = () => {
    this.resize();
    this.spread();

    var resizeID;

    let reset = () => {
      clearTimeout(resizeID);
      resizeID = setTimeout(this.reset, 400);
    }
    
    window.addEventListener("orientationchange", reset);
    window.addEventListener("resize", reset);
  }

  resize() {
    let {
      width,
      height
    } = this.canvas.getBoundingClientRect();
    
    this.width = width;
    this.height = height;

    let { tileSize } = this.props;
    
    this.grid = new CoordinateGrid(width, height, tileSize, this.spaces);
  }

  spread = () => {
    let ctx = this.canvas.getContext("2d");
    ctx.clearRect(0, 0, this.width, this.height);

    let tile, 
        delay, 
        color,
        ix, 
        rect, 
        rects,
        curr;

    let { tileSize } = this.props;

    let misfits = [];

    while (rect = this.grid.shift()) {
      rects = rect.cut(tileSize, tileSize);
      
      if (rects.length) {
        curr = rects.shift();

        if (randomNumber(20) > 12) {
          delay = randomNumber(200, 1000);
          ix = randomNumber(this.colors.length);
          color = this.colors[ix];

          tile = new Tile(this.canvas, curr.minX, curr.minY, color, tileSize, 0.35);
          tile.render(delay);
        } else {
          misfits.push(curr);
        }

        this.grid.push(...rects);
      } else {
        misfits.push(rect);
      }
    }

    this.grid.push(...misfits);    
  }

  reset = () => {
    this.resize();
    this.grid.reset();
    this.spread();
  }

  render = () => {
    let style = {
      width: "100%",
      height: "100%",
      position: "fixed",
      zIndex: -2
    };

    return <canvas ref={this.ref} style={style}/>;
  }
}
