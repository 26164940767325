import React from 'react'
import { Typography } from '@material-ui/core'

export default function Title(props) {
  const variant = props.variant || "h2";
  props = { ...props, variant: variant };
  return (
    <Typography { ...props } gutterBottom>
      { props.children }
    </Typography>
  );
}

