import React, { useState } from 'react'

import { 
    Button, 
    Card,
    CardContent,
    CardMedia,
    FormGroup,
    IconButton
} from '@material-ui/core'

import { 
    withStyles 
} from '@material-ui/core/styles'

import LibraryMusicIcon from "@material-ui/icons/AudiotrackRounded"
import VideoLibraryIcon from '@material-ui/icons/VideoLibraryRounded'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibraryRounded'
import FileCopyIcon from '@material-ui/icons/FileCopyRounded'
import PublishIcon from '@material-ui/icons/PublishRounded';

import * as mm from "music-metadata-browser" 


const styles = (theme) => ({
    card: {
        width: "300px",
        height: "300px",
        margin: "16px",
        textAlign: "center",
        backgroundColor: "rgba(29, 29, 29)",

        [theme.breakpoints.down("sm")]: {
            width: 200,
            height: 200,
        }
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap"
    },
    details: {
        flex: "1 0 auto"
    },
    media: {
        flexShrink: 1,
        height: 225,
        margin: "auto",

        [theme.breakpoints.down("sm")]: {
            height: 150
        }
    },
    fileInput: {
        display: "none"
    },
    buttonInput: {
        margin: "16px 0"
    },
    fileGroup: { 
        minHeight: "50vh",
        width: "100%",
        padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.85)",
        border: "2px dashed rgba(255, 255, 255, 0.23)",
        borderRadius: "10px 10px",
        "&:hover": {
            borderColor: "rgba(255, 255, 255, 0.8)"
        }
    }
});

export class Icon extends React.Component {
    defaults = {
        "audio": LibraryMusicIcon,
        "video": VideoLibraryIcon,
        "image": PhotoLibraryIcon,
    }

    _getGenericType(fileType) {
        return fileType.split("/")[0];
    }

    render() {
        const { 
            type, 
            ...props 
        } = this.props;
        const Tag = this.defaults[this._getGenericType(type)] || FileCopyIcon;

        return <Tag { ...props }/>;
    }
}

class _FileCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: ""
        };
    }

    componentDidMount() {
        let { file } = this.props;
        if (file && file.type.includes("image")) {
            this.setState({ url: URL.createObjectURL(file) });
        } else {
            mm.parseBlob(file).then(metadata => {
                let { picture } = metadata.common;
                if (typeof picture !== "undefined") {

                    this.setState({ url: URL.createObjectURL(picture) }); 
                }
            });
        }
    }

    render() {
        let { file, classes } = this.props;
        let { url } = this.state;
        let media;

        if (url.length > 0) {
            media = (
                <CardMedia
                    component="img"
                    className={ classes.media }
                    src={ url } 
                    alt={ file.name }
                /> 
            );
        } else {
            media = (
                <Icon 
                    type={ file.type } 
                    className={ classes.media }
                    fontSize="large"
                />
            );
        }
        return (
            <Card className={ classes.card }>
                <div className={ classes.content }>
                    { media }
                    <div className={ classes.details }>
                        <p>{ file.name }</p>
                    </div>
                </div>
            </Card>
        );
    }
}

export const FileCard = withStyles(styles)(_FileCard);

class FileUploader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
        };  
    }

    validFileType(file) {
        return true; 
    }


    handleChange = (e) => {
        e.preventDefault();
        let files = [];

        for (const file of e.target.files) {
        // for (const file of this.refInput.current.files) {
            if (this.validFileType(file)) {
                files.push(file);
            }
        }

        this.setState({ files: [ ...this.state.files, ...files ] });
    }

    handleDragOver = (e) => {
        e.preventDefault();
    }

    handleDrop = (e) => {
        e.preventDefault();
        let files = [];

        for (const item of e.dataTransfer.items) {
            if (item.kind === "file") {
                let file = item.getAsFile();

                if (this.validFileType(file)) {
                    files.push(file);
                }
            }
        }

        this.setState({ files: [ ...this.state.files, ...files ]})
    }

    render() {
        let { 
            accept, 
            classes,
            children,
            onChange = this.handleChange,
            onDrop = this.handleDrop,
            onDragOver = this.handleDragOver
        } = this.props;
        let { files } = this.state;

        return ( 
            <>
                <label htmlFor="file-input" className={ classes.buttonInput }>
                    <Button component="span">
                        <PublishIcon fontSize="large"/>Select Files
                    </Button>
                </label>
                <input 
                    id="file-input"
                    type="file" 
                    multiple 
                    accept={ accept } 
                    onChange={ onChange }
                    className={ classes.fileInput }
                />
                <FormGroup row className={ classes.fileGroup } onDragOver={ onDragOver } onDrop={ onDrop }>
                    { children.length > 0 ? children : 
                        files.length > 0 ? files.map(file => {
                            return (
                                <FileCard 
                                    file={ file } 
                                    key={ file.name + file.lastModified } 
                                    classes={ classes }
                                />
                            );
                        }) : 'Drag and drop your files here or use the "select files" button above'
                    }
                </FormGroup>
            </>
        );
    }
}

export default withStyles(styles)(FileUploader);